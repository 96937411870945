.container {
  --font-size: 1em;
  border: 0px;
  padding: 0px;
  text-decoration: none;
  background: unset;

  .link {
    --shadow: calc(var(--font-size) * 0.36);
    --animation: 0.02s linear;
    --border-width: calc(var(--font-size) * 0.08);
    svg {
      min-height: calc(var(--font-size) * 1.14);
      min-width: calc(var(--font-size) * 1.14);
    }
    border: var(--border-width) solid var(--tertiary);
    display: block;
    color: inherit;
    font-size: var(--font-size);
    font-weight: bold;
    display: flex;
    padding: var(--font-size);
    white-space: nowrap;
    box-shadow: var(--shadow) var(--shadow) var(--tertiary);
    margin: 0 var(--shadow) var(--shadow) 0;
    justify-content: space-around;
    width: calc(100% - var(--shadow));
    align-items: center;
    transition:
      box-shadow var(--animation),
      margin-top var(--animation),
      margin-left var(--animation),
      margin-bottom var(--animation),
      opacity var(--animation);

    &.dark-bg {
      border: var(--border-width) solid var(--fore);
      box-shadow: var(--shadow) var(--shadow) var(--tertiary), var(--shadow) var(--shadow) 0px var(--border-width) var(--fore);
    }

    :hover&,
    :focus-visible& {
      --bottom: 4;
      --top: 1;
      --unit: calc(var(--shadow) / calc(var(--bottom) + var(--top)));
      --bottom-value: calc(var(--unit) * var(--bottom));
      --top-value: calc(var(--unit) * var(--top));
      box-shadow: var(--bottom-value) var(--bottom-value) var(--tertiary);
      margin: var(--top-value) var(--bottom-value) var(--bottom-value) var(--top-value);
      opacity: 0.9;
      outline: 0;

      &.dark-bg {
       box-shadow: var(--bottom-value) var(--bottom-value) var(--tertiary), var(--bottom-value) var(--bottom-value)  0px var(--border-width) var(--fore);
      }
    }

    :active& {
      box-shadow: 0px 0px var(--tertiary);
      margin: var(--shadow) 0 0 var(--shadow);

      &.dark-bg {
        box-shadow: 0px 0px var(--tertiary), 0px 0px 0px var(--fore);
      }
    }
  }
}
