.main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
  height: 100dvh;
  img {
    width: stretch;
    height: auto;
    object-fit: fill;
  }
}
