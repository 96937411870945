.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  color: var(--fore);
  .logo {
    width: 61dvw;
    margin: 60px 0 40px 0;

    :global(#ember-testing) & {
      width: calc(375px * 0.61);
    }
  }
  .copyright {
    margin-bottom: 17px
  }
  .links {
    display: flex;
    gap: 20px;
    margin-bottom: 36px
  }
}
