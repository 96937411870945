.grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-content: space-between;
  justify-items: center;
  flex-wrap: wrap;
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

.item {
  width: 100%;
  max-width: 100px;
  max-height: 100px;
}

.img {
  background: white;
  box-shadow: 1px 1px 3px black;
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  aspect-ratio: 1;
}
