.nav-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 8rem;
}

.nav {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  font-size: 1.4rem;
  color: var(--tertiary);
  a, button {
    align-items: center;
    cursor: pointer ;
    display: flex;
    justify-content: space-between;
    background: var(--fore);
    border: none;
    border-bottom: 1.5px solid var(--tertiary);
    padding: 1rem 0;
    text-decoration: none;
    text-align: left;

    svg:first-child {
      margin-right: 1rem;
    }
    &:active, &:visited {
      color: var(--tertiary);
    }

    &:hover, &:focus {
      color: var(--secondary);
    }
  }
}
