.not-found {
  align-items: center;
  color: var(--tertiary);
  font-weight: 300;
  text-align: center;

  .btn {
    width: 100%;
    div {
      background: var(--secondary);
    }
  }

  h1 {
    font-size: 2rem;
  }

  img {
    height: 50dvh;
  }
}
