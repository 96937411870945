.index {
  @media(orientation: landscape) {
    .portrait {
      display: none;
    }
  }

  @media(orientation: portrait) {
    .landscape {
      display: none;
    }
  }
}

.login-btn {
  font-size: 14px;
  color: var(--tertiary);
  .login-txt {
    margin: 0 18px;
  }
}

.caption {
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 1.4rem;
}

.info {
  font-size: 1rem;
  background: #ffffffaa;
  z-index: 1;
}

.life {
  margin-bottom: 1rem;
}

.section {
  @media (orientation: landscape) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

.picture {
  display: flex;
  justify-content: center;
  min-height: 0px;
  overflow: visible;

  img {
    height: fit-content;
    max-width: 40dvh;

    .intro & {
      max-width: 65dvh;
      @media (orientation: landscape) {
        min-width: 35rem;
        max-width: stretch;
      }
    }

    :global(#ember-testing) & {
      max-width: max(calc(375px * 0.4), calc(667px * 0.4));
    }
    @media (orientation: landscape) {
      max-width: unset;
    }
  }

  @media (orientation: landscape) {
    width: 50%;
  }
}

.side-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  @media (orientation: landscape) {
    width: 50%;
  }
}

.ls-order-zero {
  @media (orientation: landscape) {
    order: 0;
  }
}

.ls-order-one {
  @media (orientation: landscape) {
    order: 1;
  }
}

.intro {
  background-color: var(--fore);
  color: var(--tertiary);
  scroll-snap-align: start;
  @media (orientation: landscape) {
    flex-direction: row;
    align-items: center;
  }
}

.frequency {
  background-color: var(--secondary);
  @media (orientation: landscape) {
    flex-direction: row;
    align-items: center;
  }
  .frequency-picture img {
    @media (orientation: landscape) {
      width: 80%;
    }
  }
}


.usage {
  background-color: var(--primary);
  color: var(--tertiary);
  .picture {
    img {
      width: auto;
      max-height: max(46dvh, 46dvw);
    }
  }
  @media (orientation: landscape) {
    flex-direction: row;
    align-items: center;
  }
  .caption {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
}

.hashtag {
  background-color: var(--tertiary);
  text-align: center;
  @media(orientation: landscape) {
    align-items: center;
  }

  .hashtag-animated {
    max-width: stretch;
    max-height: 30vh;
    object-fit: contain;
  }
}

.price {
  background-color: var(--tertiary);
  .window {
    color: var(--tertiary);
    background: var(--fore);
    flex-grow: 1;
    border-radius: 4rem;
    margin-bottom: 26px;
    padding: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media(orientation: landscape) {
      align-items: center;
      flex-direction: row;
    }

    .caption {
      text-align: center;
      @media(orientation: landscape) {
        text-align: left;
      }
    }

    .list li {
      background-image: url("/images/tick.svg");
      background-size: 1.6rem;
      background-repeat: no-repeat;
      padding-left: 2rem;
      line-height: 1.4rem;
      list-style-type: none;
      padding-bottom: 1rem;
    }

    .cost {
      --cost-font-size: 2.4rem;
      svg {
        height: 3rem;
        width: 3rem;
      }
      display: flex;
      line-height: 0.8;
      justify-content: space-between;
      margin: 1rem;
      @media(orientation: landscape) {
        --cost-font-size: 3.3rem;
        height: 100%;
        width: 50%;
        flex-direction: column;
        justify-content: space-around;
        svg {
          height: 3.5rem;
          width: 3.5rem;
        }
      }
    }
  }

  @media(orientation: landscape) {
    .portrait {
      display: none;
    }
  }

  @media(orientation: portrait) {
    .landscape {
      display: none;
    }
  }
}

.abyss {
  background-color: var(--fore);
  color: var(--tertiary);
  @media (orientation: landscape) {
    flex-direction: row;
    align-items: center;
  }
  .abyss-picture img {
    @media (orientation: landscape) {
      width: 80%;
    }
  }
  .caption {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
}
