.header {
  margin: 0;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media (orientation: landscape) {
    width: unset;
    padding-right: 4rem;
    padding-left: 4rem;
  }
 
  .logo {
    width: 50dvw;
    max-height: 1.6em;
    .mark& {
      width: 56px;
      height: 56px;
      max-height: 56px;
    }

    :global(#ember-testing) & {
      width: calc(375px * 0.5);
    }
    @media (orientation: landscape) {
      width: unset;
   }
  }

  h1 {
    margin: 0;
    line-height: 0px;
  }
  a {
    display: block;
  }
}
