.subscribe {
  background: var(--fore);
}

.label {
  color: var(--tertiary);
  margin-right: 0.2rem;
}

.input {
  width: 8rem;
  border: none;
}

.combo {
  font-size: 1rem;
  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
}

.comboButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.drop {
  z-index: 10;
  position: absolute;
  max-height: 6rem;
  min-height: 1rem;
  overflow: auto;
  background: var(--fore);
  margin-top: 0.2rem;
  border: 0.1rem solid var(--tertiary);
  box-shadow: 0.5rem 0.5rem var(--tertiary);

  [data-is-active="true"] {
    color: var(--tertiary);
    background: var(--primary);
  }
  width: 8rem;
}

.option,
.input {
  color: var(--secondary);
  padding: 0.2rem;
  cursor: pointer;
}

.trigger {
  position: relative;
  display: flex;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
