.legal {
  margin-bottom: 4rem;
  color: var(--tertiary);
  height: fit-content;

  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scroll-snap-align: center;

  .sub-section {
    line-height: 2rem;
    p {
      font-weight: 300;
      font-size: 1rem;
    }

    h2 {
      font-size: 2.2rem;
    }

    h3 {
      margin: 3rem 0 1rem 0;
    }
  }
}
