.success {
  .btn {
    div {
      background: var(--secondary);
    }
  }

  img {
    height: 65dvh;
  }
}
