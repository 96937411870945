.section {
  color: var(--tertiary);
  gap: 1rem;
}

.photos {
  margin-top: 1em;
}

.photosDescription {
  margin: 1em;
  text-align: center;
}

.username {
  text-align: center;
}

.boxes {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.box {
  border: 1.5px solid var(--tertiary);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    text-align: center;
    margin: 0;
    font-size: 1rem;
  }

  dl {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
    gap: 1rem 0;
  }

  dt,
  dd {
    display: inline-block;
    margin: 0;
    height: fit-content;
  }

  dt {
    font-weight: 500;
    border-bottom: 1px solid lightgray;
    padding-bottom: 0.8rem;
  }

  dd {
    --font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size);
    svg {
      height: var(--font-size);
    }
    max-width: fit-content;
    gap: 1rem;
    padding: 0.3rem 0;
  }

  .active,
  .inactive {
    border-radius: 1rem;
    padding: 0.3rem 0.4rem 0.3rem 1rem;
    color: var(--fore);
    font-weight: 500;
  }

  .active {
    background: #30bb1a;
  }

  .inactive {
    background: lightgray;
  }

  .btn {
    display: flex;
    justify-content: center;
    text-decoration: none;
    background: var(--tertiary);
    border: none;
    border-radius: 1rem;
    padding: 1.6rem;
    width: 100%;
    color: var(--fore);
    font-weight: 500;
    cursor: pointer;
  }

  .insta {
    background: var(--insta-gradient);
  }
}
.manage {
  height: 50%;
}
.authorize {
  min-height: 30%;
}
